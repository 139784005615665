<template>
  <div class="digitizing-box" v-wechat-title="'数字化转型'">
    <div class="digitizing-content-box">
      <p class="digitizing-title">中软鸿联<br>畅通<span>数字化转型</span>的自主物联网之路</p>
      <div>
        <p>
          随着疫情防控常态化和中国经济向高质量增长转型，以及云计算、大数据、人工智能和5G等技术的共同作用，数字化转型得到前所未有的跨越式发展。北京的“五新”政策、上海的“城市数字化”、广东的“制造业数字化”、江苏的“智改数转”、四川的“灯塔⼯⼚”……除了各地政府根据资源禀赋制定的产业政策以外，推动数字化转型的扶持政策也逐渐落到实处，全面数字化的时代大势已经到来。</p>
        <img src="@/assets/img/401629231@2x.png" alt="401629231@2x">
        <p>
          物联网是数字化“新基建”的核心要素，也是数字化转型的关键节点。比如传统制造企业已不再是埋头造东西了，而是通过收集产品的各项使用指标、用户习惯等数据，据此优化产品，提升用户满意度，而每个产品都可以通过不同的网络介质与云端通信，基于数据的高效、稳定传输，实现跨产品间的智联互通。所以说推进数字化转型的大势下，物联网是必经之路。</p>
        <img src="@/assets/img/111@2x.png" alt="111@2x">
        <p>鸿联联创营是中软国际打造的AIoT平台品牌，致力基于平台打造自主创新AIoT生态，鸿联联创营携手深开鸿联合各地政府，通过建设“一平台、六中心”，夯实数字化底座，加快政策落地，为各地政企数字化转型畅通物联网之路。</p>
      </div>
    </div>
    <div class="digitizing-center-box">
      <div>
        <span>一平台</span>
        <strong>自主创新AIoT生态平台</strong>
        <div>打造以开源鸿蒙为代表的区域国产化AIoT生态服务数据底座，构建SOC、OS、开源组件、行解方案等八大核心库，结合开鸿OS定制能力、生态整合能力、方案拆解能力等六大核心能力，<span>提供知识搜索、国产替代分析、生态服务供应、低代码开发等系统功能模块。</span>
        </div>
        <span>六中心</span>
        <strong>构建自主生态体系</strong>
        <p><span>数智转型中心</span></p>
        <div>打造企业自主创新、数字化转型一站式服务中心，为当地企业提供政策辅导、定位咨询、场景牵引、国产IoT替代策略等服务；</div>
        <p><span>智联万物中心</span></p>
        <div>打造各类物联网及企业数字化转型的场景案例示范，结合实际场景案例提供转型参考体验，通过实感体验，引入物联网创新思维，加速政企数字化转型；</div>
        <p><span>智联共创中心</span></p>
        <div>采用“共创模式”，聚合优秀资源和能量来布局物联网应用服务，与当地传统行业的企业组成共创团队，并邀请第三方高科技人才加入，从开鸿操作系统根技术出发打通数据感知、数据传输、云平台等各个层级，进行行业应用的联合创新；
        </div>
        <p><span>开源创新中心</span></p>
        <div>携手深开鸿，基于鸿联云平台汇聚的国产开源创新生产要素，为企业提供国产开源硬件、系统及组件支持，并拉通当地IoT行业生态企业，组建IoT开源行业联盟，联合联盟企业共同制定开源IoT行业技术规范；</div>
        <p><span>未来实验中心</span></p>
        <div>整合网络条件、仪器设备、技术团队、智库专家、产业链合作资源，为行业客户的方案选型、概念验证、样品研发、生态链合作、行业培训等提供全新的平台与全面的技术支撑；</div>
        <p><span>鸿联英才中心</span></p>
        <div>结合园区和企业数字化转型实际需求，引入各类开源资源、各类培训课程，以培养实用型人才为目标，推出自主可控物联网实训、工业互联网实训和智能制造实训等课程体系，为地方数字化转型培训实战型人才。</div>
        <img src="@/assets/img/5129@2x.png" alt="111@2x">
        <div>
          鸿联联创营通过一个以数据服务和技术共享为核心的生态云平台，六个全方位覆盖技术、数据、人才等多要素的赋能中心，构建出一个市场化服务与公共服务双轮驱动的自主创新AIoT生态，在各地修筑出一条条物联网高速公路，承载着千行百业的数字化转型速高且稳。
        </div>
      </div>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import share from '../components/share.vue'

export default {
  components: {
    share
  },
  data () {
    return {
      shareInfo: {
        title: '数字化转型',
        desc: '中软鸿联畅通数字化转型的自主物联网之路',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553281456623636481`
      }
    }
  },
  mounted () {
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.digitizing-box {
  .digitizing-content-box {
    padding: 30px 16px 24px;
    background: url(../../assets/img/5142@2x.png) no-repeat;
    background-size: 100% 100%;

    > p {
      padding: 0 14px;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      color: #333333;

      > span {
        color: #EA0B06;
      }
    }

    > div {
      padding: 24px 14px;
      background: #fff;

      > p {
        font-size: 12px;
        line-height: 18px;
        color: #666666;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      > img {
        display: block;
        max-width: 100%;
        border: 4px solid #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        margin-bottom: 24px;
      }
    }
  }

  .digitizing-center-box {
    padding: 24px 16px;
    background: url(../../assets/img/1@4x@2x.png) no-repeat;
    background-size: 100% 100%;

    > div {
      padding: 24px 14px;
      background: url(../../assets/img/sjbg.1f64b3b8@2x.png) no-repeat;
      background-size: 100% 100%;

      > span {
        display: block;
        width: 121px;
        height: 36px;
        background: url(../../assets/img/5141@2x.png) no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 24px;
        padding-left: 22px;
      }

      > strong {
        font-size: 16px;
        line-height: 22px;
        color: #EA0B06;
        display: block;
        margin-bottom: 24px;
      }

      > div {
        font-size: 12px;
        line-height: 18px;
        color: #666666;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        > span {
          color: #DB1915;
        }
      }

      > p {
        position: relative;
        padding-left: 6px;
        margin-bottom: 16px;

        > span {
          display: block;
          position: relative;
          z-index: 2;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #333333;
        }

        &::after {
          content: '';
          position: absolute;
          width: 70px;
          height: 10px;
          background: #EA0B06;
          opacity: 0.2;
          border-radius: 10px;
          left: 0;
          bottom: 0;
        }
      }

      > img {
        display: block;
        max-width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
